import styled from '@emotion/styled'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { mq } from './../utility/breakpoint'
import { navigate } from 'gatsby'
import { LoadingIndicator } from '../components/atoms/loadingIndicator'
import { AppointmentService, TopicsService } from './../api'

import { PageContainer } from '../components/organisms/pageContainer'
import { PageContainerOuter } from '../components/organisms/pageContainerOuter'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Paragraph } from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
const SalesformPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const { t } = useTranslation(`appointment`)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [])

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en${document.location.search}`)
    }

    return <></>
  }

  return (
    <PageFrame {...params} hideMenu={true} pageName="index">
      <PageContainerOuter>
        <PageContainer>
          <StartTeaser>
            <StartTeaserColumn>
              <img src="/assets/appointment-visual.jpg" />
            </StartTeaserColumn>
            <StartTeaserColumn>
              <Heading type="h2">{t(`registered.header`)}</Heading>
              <Paragraph>{t(`registered.copy`)}</Paragraph>{' '}
              <Button
                onClick={() =>
                  window.open(`https://www.brose.com/future-mobility/`)
                }
              >
                {t(`registered.cta`)}
              </Button>
            </StartTeaserColumn>
          </StartTeaser>
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default SalesformPage

const StartTeaser = styled.div(
  () => `

  ${mq[3]}{
    display:flex;
    gap:130px
  }
  `
)
const StartTeaserColumn = styled.div(
  () => `flex:1;
  img{  width:100%;

    ${mq[3]}{ width:80%; };
  }`
)
const Spacer = styled.div`
  margin: 95px 0;
  ${mq[3]} {
    margin: 125px 0;
  }
`
